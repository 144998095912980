<template>
  <div>
    <standbyDialog :localLoadingDialog="localLoadingDialog"></standbyDialog>
    <a class="openAddField" @click="addField()">
      <slot></slot>
    </a>
    <dialogContainer
        v-if="showContentDialog && dialogData != null"
        :dialogAttributes="dialogAttributes"
        :result="dialogData.result"
        v-on="$listeners"
        @dialog-result-changed="updateResult(...arguments)"></dialogContainer>
    <confirmationDialog
        :message="message"
        :value="showConfirmationDialog"
        maxWidth="290"
        title="Notice"
        @confirmation-ok="closeCheckDailog()"></confirmationDialog>
  </div>
</template>
<script>
import {getRandomInt} from '@/js/helper.js';
import standbyDialog from '@/commonComponents/standbyDialog.vue';
import dialogContainer from '@/commonComponents/dialogContainer.vue';
import confirmationDialog from '@/commonComponents/confirmationDialog.vue';
import requests from '@/js/requests';
import {setURLSearchParam} from '@/js/general';
import {tokenRefresher} from "@/auth-tools";

export default {
  components: {
    dialogContainer,
    confirmationDialog,
    standbyDialog,
  },
  props: {
    path: String,
    additionalAttributes: Object,
    dialogAttributes: Object,
    showDialog: {default: false},
  },
  data: function () {
    return {
      message: '',
      showConfirmationDialog: false,
      openDialog: false,
      dialogResponse: null,
      localLoadingDialog: false,
      showContentDialog: false,
      dialogRef: 'dialog' + getRandomInt(0, 1000),
      dialogDataObject: this.$store.state.dialogDataObject,
    };
  },
  watch: {
    showDialog(newVal) {
      if (newVal) {
        if (typeof this.additionalAttributes.dialogCall !== 'undefined' && this.additionalAttributes.dialogCall !==
            '') {  //for testinfo direction click < >
          this.addField();
        } else if (typeof this.additionalAttributes.dialogCall === 'undefined') { //for all other call!
          this.addField();
        }
      } else {
        this.showContentDialog = false;
      }
    },
  },
  computed: {
    dialogData: {
      get() {
        let dialogData = null;
        if (typeof this.dialogDataObject[this.dialogRef] != 'undefined') {
          dialogData = this.dialogDataObject[this.dialogRef];
        }
        return dialogData;
      },
      set(newData) {
        this.$emit('dialog-changed', newData);
      },
    },
  },
  created: function () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'closeDialog' && state.dialogRef === this.dialogRef) {
        this.dialogResponse = null;
        this.showContentDialog = false;
        this.$emit('dialog-closed', false);
      }
      if (mutation.type === 'openResultDialog' && state.dialogResult.dialogRef === this.dialogRef) {
        console.log(state.dialogResult.json.error);
        this.message = state.dialogResult.json.error;
        this.showConfirmationDialog = true;
      }
      if (mutation.type === 'dialogResultChanged') {
        this.dialogDataObject = state.dialogDataObject;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    updateResult(newResult) {
      this.$set(this.dialogResponse.data, 'result', newResult);
    },
    addField() {
      this.localLoadingDialog = true;
      this.$emit('loading-dialog', true);

      let options = {};
      if (typeof this.additionalAttributes != 'undefined'
          && typeof this.additionalAttributes.type != 'undefined'
          && this.additionalAttributes.type === 'customDialog') {
        options = {
          ...this.additionalAttributes,
          requestType: 'ajax',
        };
      } else {
        options = {
          function: 'getInsertedFields',
          requestType: 'ajax',
        };
        options = {...options, ...this.additionalAttributes};
      }

      let caller = this;
      requests.frameworkAxiosRequest({
        method: 'post',
        url: this.path,
        data: options,
      }).then(function (response) {
        if (response?.["X-Session-Status"]?.status === "session-out") {
          tokenRefresher.logout(); //clear out the tokens
          return
        }
        caller.localLoadingDialog = false;
        caller.showContentDialog = true;
        caller.$emit('loading-dialog', false);
        caller.dialogResponse = response;
        caller.$set(
            caller.dialogResponse.data.result,
            'dialogRef',
            caller.dialogRef,
        );

        caller.$store.commit('dialogResultChanged', caller.dialogResponse.data.result);
        caller.$emit('dialog-loaded', true);
        if (typeof response.data.result.recallFunction != 'undefined' && response.data.result.recallFunction === true) {
          caller.addField();
        }
      }).catch(function (response) {
        console.log(response);
      });

      if ((this.$route.name === 'configure-and-run-tests') && this.additionalAttributes) {
        setURLSearchParam('testCaseId', this.additionalAttributes.n_id);
      }
    },
    closeCheckDailog() {
      this.showConfirmationDialog = false;
      this.$store.commit('dialogButtonsLoading', false);
    },
  },
};
</script>
